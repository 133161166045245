import React, {useEffect,useState,forwardRef,useRef,useImperativeHandle } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button} from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import Select from "react-select";
import Countdown, { zeroPad } from "react-countdown";
import $ from 'jquery';
import axios from 'axios';
import config from '../lib/config';

import {
  getCurAddr
} from '../actions/v1/User';

import {
  CollectiblesList_Home,
  CollectiblesListcount_Home,
  GetCategoryAction,
  GetItemList
} from '../actions/v1/token';

import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import HeaderSearch from './separate/HeaderSearch'
import DETH_ABI from '../ABI/DETH_ABI.json';
import TokenItem from './separate/Token-Item';
import BidPopup from './separate/Bid-Popup';
import ConnectWallet from './separate/Connect-Wallet';

import ReactLoading from 'react-loading';

import Web3 from 'web3';
import '@metamask/legacy-web3'

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const dashboardRoutes = [];
const drawerWidth = 240;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

const NFT =(props) => {
  const [open,setOpen] =useState(true)
   const [anchorEl,setAnchorEl] =useState(null)



  const handleDrawerOpen = () => {
    console.log("data---------------")
    setOpen(!open)
  };

  const handleDrawerClose = () => {
        setOpen(true)
  };

  const handleMenu = event => {
    console.log("----event",event)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
setAnchorEl(null);
  };


  const LikeForwardRef = useRef();
  const PutOnSaleForwardRef = useRef();
  const PurchaseNowForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const BurnForwardRef = useRef();
  const CancelOrderForwardRef = useRef();
  const WalletForwardRef = useRef();
  const ReportForwardRef = useRef();
  const ShareForwardRef = useRef();
  

  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [totalcollections, setTotalCollections] = React.useState(0);
  const [totalarts, setTotalarts] = React.useState(0);
  const [buyerList, setbuyerList] = React.useState([]);
  const [sellerList, setsellerList] = React.useState([]);
  const [top5buy,settop5buy] = React.useState([]);
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [recenttoken,setrecenttoken] = React.useState([])
  const [HitItem, Set_HitItem] = useState({});
  const [Keyword, Set_Keyword] = useState("");
  const [CategoryOption, setCategoryOption] = React.useState({});
  
  const [Categorylist, setCategorylist] = React.useState([]);
  const [TokenList, setTokenList] = React.useState([]);
  const [CatName, setCatName] = React.useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = React.useState({'loader':false,'All':{page:1,list:[],onmore:true}});
  const [Page, setPage] = React.useState(1);
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [allCount, setallCount] = React.useState({'Salecount':0, 'CreatersCount':0, 'CollectionCount':0});
  const [pagecount,setpagecount] = React.useState(0);
  const [currentpage,setcurrentpage] = React.useState(1);
  const [pagearray,setpagearray] = React.useState([]);
  const [pageindex, setpageindex] = React.useState(3);

  //by muthu
  const [item, Set_item] = useState({}); 
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [totaluserbidAmt,setTotaluserbidAmt] = React.useState(0);

  
  async function CategoryListCall() {
    axios
    .get(`${config.vUrl}/token/category/list`)
    .then(response => {
      console.log('response',response)
      if (response && response.data && response.data.list) {
        setCategorylist(response.data.list);
      }
    })
    .catch(e => console.log(e))
  }

  const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page+1;
    setCatBasedTokenList(CatBasedTokenList);
    

    TokenListCall({
      page : CatBasedTokenList[CatName].page+1
    });
    getcount({
      page : CatBasedTokenList[CatName].page+1
    });
  }

  async function selectChange(name) {
    if(name.label != CatName) {
      setCatName(name.label);
      if(typeof CatBasedTokenList[name.label] == 'undefined'){
        CatBasedTokenList[name.label] = {page: CatBasedTokenList[CatName].page,list:[],onmore:true};
        setCatBasedTokenList(CatBasedTokenList);
        console.log(CatBasedTokenList,"=============================CatBasedTokenList0")
        TokenListCall({CatName:name.label,page: CatBasedTokenList[CatName].page});
        getcount({CatName:name.label,page: CatBasedTokenList[CatName].page})
      }
    }
  }

  async function TokenListCall(data={}) {
    var currAddr = await getCurAddr();
    var name = CatName;
    if (data.CatName) {
      name = data.CatName
    }
    var payload = {
      limit: config.limit,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page)?CatBasedTokenList[name].page:1,
      currAddr: currAddr,
      CatName: name,
      from:'Home'
    }
    CatBasedTokenList.loader = true;
    console.log(CatBasedTokenList,"==================================CatBasedTokenList")
    setCatBasedTokenList(CatBasedTokenList);

    var resp = await CollectiblesList_Home(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    console.log('home resp----',resp);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list.length > 0) {
      setTokenList(resp.data.list);

      if(typeof CatBasedTokenList[name] == 'undefined'){
        CatBasedTokenList[name] = {page:1,list:[]};
      }
      CatBasedTokenList[name].list = resp.data.list;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
    else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }

  useEffect(() => {
    getInit();
  }, [])

  async function getInit() {
    CategoryListCall();
    TokenListCall();
    GetCategoryCall();
    getcount();
    SearchList();
    SearchKeyword();
  }
  async function AfterWalletConnected() {
    // CategoryListCall();
    // if(UserAccountAddr == '') {
    //   TokenListCall();
    // }
    try{
      var curAddr = await getCurAddr();
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.Spideybarder);
        var tokenBal = await CoursetroContract.methods.balanceOf(curAddr).call();
        var tokenBalance = tokenBal / config.decimalvalues;
        Set_TokenBalance(tokenBalance.toFixed(6));
      }
      LikeForwardRef.current.getLikesData();
    }catch(err){

    }
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  //const classes = useStyles();
  const { ...rest } = props;
 

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

 

  const keyPress = async (e) => {
    if (e.keyCode == 13) {
        console.log('value', e.target.value,Keyword);
        
        
    }
  }

  const searchChange = async (e) => {
    var value = e.target.value;
    if (value && value != "") {
        Set_Keyword(value);
    }else{
        Set_Keyword("");
    }
  }

  async function GetCategoryCall() {
    var resp = await GetCategoryAction()
    if (resp && resp.data && resp.data && resp.data.list) {
        // var CategoryOption = [];
        var CategoryOption=[{label:"All",value:"All"}];

        resp.data.list.map((item) => {
        CategoryOption.push({
          name:'TokenCategory',
          value: item._id,
          label: item.name
        })
      })
      setCategoryOption(CategoryOption)
    }
  }

  async function showprevious(){
   if(CatBasedTokenList[CatName].page!=1){
    var pape= CatBasedTokenList[CatName].page-1
    console.log(pape,"===================pape")
    setcurrentpage(pape)
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page-1;
    setCatBasedTokenList(CatBasedTokenList);
    TokenListCall({
      page : CatBasedTokenList[CatName].page-1
    });
    getcount({
      page : CatBasedTokenList[CatName].page-1
    });
   }
  }

  async function shownext(){
    if(CatBasedTokenList[CatName].page!=pagecount){
    var pape= CatBasedTokenList[CatName].page+1
    console.log(pape,"===================pape")
    setcurrentpage(pape)
    console.log(CatBasedTokenList,"======================showned")
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page+1;
    setCatBasedTokenList(CatBasedTokenList);
    TokenListCall({
      page : CatBasedTokenList[CatName].page+1
    });
    getcount({
      page : CatBasedTokenList[CatName].page+1
    });
   }
  }

  async function showmore(cow){

    setcurrentpage(cow)
   CatBasedTokenList[CatName].page = cow;
    setCatBasedTokenList(CatBasedTokenList);
    setcurrentpage(cow)
    TokenListCall({
      page : cow
    });
    getcount({
      page : cow
    });
  }

  async function getcount(data={}){

    var currAddr = await getCurAddr();
    var name = CatName;
    if (data.CatName) {
      name = data.CatName
    }
    var payload = {
      currAddr: currAddr,
      CatName: name,
      from:'Home',
      Target:'Count'
    }
    var resp = await CollectiblesListcount_Home(payload);
    // console.log(resp.data.data,"==============coun")
    if(resp && resp.data && resp.data.data && resp.data.data.list.length>0){
     var pagecow = Math.ceil(parseInt(resp.data.data.list[0].count)/9)
     console.log(pagecow,resp.data.data.list[0].count,"============================coun==================")
     setpagecount(pagecow)
     var cowarray=[]
    for(let a=0;a<pagecow;a++){
     var data = {
      page : parseInt(a)+1
     }
     cowarray.push(data)
     if((parseInt(a)+1)==pagecow){
      setpagearray(cowarray)
     }
    }
   }else{
    setpagecount(0)
   }
  }

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  async function SearchList() {
    var searchtxt = getParameterByName('query');
    if(searchtxt && searchtxt!=null && searchtxt!="" && searchtxt!=undefined){
    var response = await GetItemList({search:searchtxt,page:currentpage});
    if(typeof CatBasedTokenList["Search"] == 'undefined'){
        CatBasedTokenList["Search"] = {page:1,list:[]};
      }
      CatBasedTokenList["Search"].list = response.result;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
      setCatName("Search")
   }
  }


  function SearchKeyword(){
    var keyword = getParameterByName('query');
    Set_Keyword(keyword)
  }

  const { classes, theme } = props;
    // const open = Boolean(anchorEl);

    // Countdown Timer
    const currentDate = new Date();
    const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

    const renderer = ({ days, hours, minutes, seconds }) => {
      return (
        <div className="timer_panel">
          <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">d</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">h</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">m</span></span>
          <span className="timer_dots"> </span>
          <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">s</span></span>
        </div>
      );
    };

    return (
      <div className={classes.root}>

<ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
        <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_TokenBalance = {Set_TokenBalance}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        TokenBalance = {TokenBalance}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <BidPopup
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        Set_NoOfToken={Set_NoOfToken}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <BurnRef
        ref={BurnForwardRef}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        GetUserBal={UserAccountBal}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />

        <ScrollToTopOnMount/>
        <CssBaseline />
        
        {/* Header APP */}
        <HeaderDashboard 
          classes={classes}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          handleMenu={handleMenu}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
        
       {/*  side Menu */}
        <SidebarLinksAfterlogin classes1={classes} open={open}/>
        <div className="overlay"></div>
        <main className={classes.content + " dashContent"}>
          <div className={classes.toolbar} />
          <div className="dashPages">              
            <GridContainer>              
              <GridItem xs={12} sm={12} md={12}>
                <h1 className="farm_title">NFT Marketplace</h1> 
                <p className="text-center">A market made for NFT, where everything is special</p>
                <div className="text-center">
                  <Button className="primary_btn mb-4">
                   {/*  <Link to="/create">Create NFT</Link>*/}
                    <Link to="/create-single">Create NFT</Link>

                  </Button>
                </div> 
                <div className="d-flex justify-content-end mb-3">
                 {/* <select class="custom-select select_menu">
                  
                  {
                    (CategoryOption && CategoryOption.length >0 && CategoryOption.map((item) => {
                      return (
                    <option selected>{item.name}</option>
                    )
                    }))
                  }
                  </select> */}
                   <Select
                      className="custom_react_select"
                      id="TokenCategory"
                      name="TokenCategory"
                      onChange={selectChange}
                      options={CategoryOption}
                      label="Single select"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  <div className="search_inp_group">
                    <HeaderSearch/>
                  </div>
                </div>
                <div className="nft_collections_panel">
                  

                {
                    (CatBasedTokenList && CatName && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list && CatBasedTokenList[CatName].list.length > 0)?(CatBasedTokenList[CatName].list.map((item) => {
                      return (
                        <TokenItem
                        item={item}
                        Set_item={Set_item}
                        LikedTokenList={LikedTokenList}
                        hitLike={LikeForwardRef.current.hitLike}
                        UserAccountAddr={UserAccountAddr}
                        UserAccountBal={UserAccountBal}
                        PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                        PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                        PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                        Set_Bids={Set_Bids}
                        Bids = {Bids}
                        Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                        Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                        Set_MyTokenBalance = {Set_MyTokenBalance}
                        Set_MyTokenDetail={Set_MyTokenDetail}
                        Set_AllowedQuantity={Set_AllowedQuantity}
                        Set_YouWillPay = {Set_YouWillPay}
                        Set_YouWillPayFee = {Set_YouWillPayFee}
                        Set_YouWillGet = {Set_YouWillGet}
                        // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                        Burn_Click={BurnForwardRef.current.Burn_Click}
                        CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                        WalletConnected={WalletConnected}
                        SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                        ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}

                        />
                      )
                    })):('No record Found')
                  }
                  
                </div>
                <div className="d-flex justify-content-end">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" onClick={()=>showprevious()} aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      {pagearray.map((page,inde)=>{
                           return inde < pageindex && inde >= parseInt(pageindex)-3 ? (
                             <li class={currentpage==parseInt(inde)+1 ? "page-item active" : "page-item"} id={"page"+parseInt(inde)+1}><a class="page-link" onClick={()=>showmore(parseInt(inde)+1)}>{parseInt(inde)+1}</a></li>
                           ):("")
                         })}
                      <li class="page-item">
                        <a class="page-link" onClick={()=>shownext()} aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </GridItem>             
            </GridContainer>
          </div>
        </main>
        
        <WalletModal/>       

      </div>
    );
}

NFT.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(NFT);
