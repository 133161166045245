import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, TextField } from '@material-ui/core';
import Countdown from "react-countdown";
import moment from "moment"

import config from '../../lib/config';
import isEmpty from '../../lib/isEmpty';

import {
    getCurAddr
  } from '../../actions/v1/User';
  
import {
    TokenCounts_Get_Detail_Action,
  } from '../../actions/v1/token';

export default function TokenItem(props) {
  async function showAllwithPro(data) {
    // console.log("click_token:",item)
    var curAddr = await getCurAddr();
    var payload = {
      curAddr:curAddr,
      tokenCounts:data.tokenCounts
    };
    TokenCounts_Get_Detail_Call(payload);
}
const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
      var TokenResp = Resp.data.Detail.Resp;
      if(
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {
        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          if(element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner != curAddr) {
            Set_BuyOwnerDetailFirst(element);
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);    
      if(TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }
      var IndexVal = -1;
      if(TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == curAddr.toString());
      }
      var newMyTokenBalance = 0;
      if(IndexVal > -1) {
        newMyTokenBalance = tokenowners_all[IndexVal].balance
        Set_MyTokenBalance(newMyTokenBalance);
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
      }
      else {
        newMyTokenBalance = 0;
        Set_MyTokenBalance(0);
        Set_MyTokenDetail({});
      }

      if(TokenResp.TotalQuantity) {
        Set_AllowedQuantity(TokenResp.TotalQuantity - newMyTokenBalance);
      }
      else {
        Set_AllowedQuantity(0);
      }
      if(TokenResp.Token && TokenResp.Token[0]) {
        Set_item(TokenResp.Token[0]);
      } 
    }
  }
    async function buyToken() {
        console.log('buyToken')
    }
    var {
        item,
        LikedTokenList,
        hitLike,
        UserAccountAddr,
        UserAccountBal,
        PutOnSale_Click,
        PlaceABid_Click,
        PurchaseNow_Click,
        Burn_Click,
        CancelOrder_Click,
        WalletConnected,
        ShareSocial_Click,
        SubmitReport_Click,
        //by muthu
        Set_item,
        Set_Bids,
        Set_BuyOwnerDetailFirst,
        Set_tokenCounts_Detail,
        Set_MyTokenBalance,
        Set_MyTokenDetail,
        Set_AllowedQuantity,
        Bids
    } = props;
    async function buyToken() {
        console.log('buyToken')
    }
    var {
        item,
        LikedTokenList,
        hitLike,
        UserAccountAddr,
        UserAccountBal,
        PutOnSale_Click,
        PlaceABid_Click,
        PurchaseNow_Click,
        Burn_Click,
        CancelOrder_Click,
        WalletConnected,
        ShareSocial_Click,
        SubmitReport_Click,
        Set_item,
        Set_Bids,
        Set_BuyOwnerDetailFirst,
        Set_tokenCounts_Detail,
        Set_MyTokenBalance,
        Set_MyTokenDetail,
        Set_AllowedQuantity,
        Bids
    } = props;
    console.log("UserAccountAddr",UserAccountAddr,item.tokenowners_current,Bids)

    const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
        }
    };

    var currDate = new Date();
    var startdate = new Date(item.clocktime);
    var enddate = new Date(item.endclocktime);
    var auction = "false";
    var finish = "";
    var enddate1 = "";
    var showlist = "true";
    if (item.type==721&&item.PutOnSaleType=="TimedAuction") {
        auction = "true";
        var a = moment(item.clocktime);
        var b = moment(item.endclocktime);
        var c = moment();
        a.diff(b); // 86400000
        var diffInMs = a.diff(c);
        finish = b.diff(c);
        enddate1 = parseFloat(diffInMs);
      // if(currDate<startdate){
      //   var auctionTxt = "Start";
      // }else
       if (finish > 0) {
        showlist = "true";
        } else {
        var auctionTxt = "Ended";
        showlist = "false";
        }
    }

    return (
    <div>
        <div className="item">
            <div className="d-flex justify-content-end">
                <div class="dropdown">
                    <span className="nft_more_dropdown" onClick={() => showAllwithPro(item)} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton">
                      <i class="bi bi-three-dots"></i></span>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                        {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                            <a class="dropdown-item" onClick={() => PutOnSale_Click(item, item.tokenowners_current)} href="#">{item.tokenowners_current.tokenPrice > parseFloat(0) ?'Change price':'Put on sale'}</a>
                        }
                        {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr) && item.tokenowners_current.tokenPrice > 0 &&
                            <a class="dropdown-item" onClick={() => CancelOrder_Click(item,item.tokenowners_current)} href="#">Cancel Order</a>
                       }
                       {(WalletConnected == true && item.tokenowners_current && (item.PutOnSaleType=='FixedPrice'|| item.PutOnSaleType=='TimedAuction'|| item.PutOnSaleType=='UnLimitedAuction') && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                        <a class="dropdown-item" onClick={() => Burn_Click(item, item.tokenowners_current)} href="#">Burn</a>
                       }
                       {(WalletConnected == true && item.tokenowners_current && item.PutOnSaleType=='FixedPrice' && item.tokenowners_current.tokenPrice > parseFloat(0) &&  item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                        <a class="dropdown-item" onClick={() => PurchaseNow_Click(item)} href="#">Purchase Now</a>
                        }
                     {(WalletConnected == true && item.tokenowners_current  && (item.PutOnSaleType=='FixedPrice'|| (item.PutOnSaleType=='TimedAuction' && showlist == "true") || item.PutOnSaleType=='UnLimitedAuction' ) && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                         Bids
                         && Bids.myBid
                         && !Bids.myBid.status
                         ?
                        <div className="menu_itm dropdown-item">
                            <span onClick={() => PlaceABid_Click(item)}>New Bid</span>
                        </div>
                        :
                        Bids
                        && Bids.myBid
                        && Bids.myBid.status
                        && Bids.myBid.status == 'pending' ?
                        <div className="menu_itm dropdown-item">
                            <span onClick={() => PlaceABid_Click(item)}>Edit bid </span>
                        </div>
                        :
                        <div></div>
                        }
                         {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                         <a class="dropdown-item" onClick={() => ShareSocial_Click(item)} href="#">Share</a>
                        }
                        {(WalletConnected == true && item.tokenowners_current  && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                            <a class="dropdown-item" onClick={() => SubmitReport_Click(item)} href="#">Report</a>
                        }
                        {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                         <a class="dropdown-item" onClick={() => ShareSocial_Click(item)} href="#">Share</a>
                        }

                        
                    </div>
                </div>
            </div>
            <div className="nft_img_panel">
                 <Link to={"/info/"+item.tokenCounts}>
                 {
                  item.image.split('.').pop() == "mp4" ?
                  <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                  :
                  item.image.split('.').pop() == "mp3" ?
                  <img src={`${config.Back_URL}/images/music.png`} alt="" className="img-fluid" style={{backgroundColor:"white"}}/>
                  :
                  <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                }
                </Link>
                  {item.image.split('.').pop() == 'mp3' ?
                  <audio src={item.ipfsimage != "" ? `${config.ipfsurl}${item.ipfsimage}` : `${config.Back_URL}/images/noimage.png`}  type="audio/mp3"controls className="audio">
                  </audio>:""
                }

                {item && item.PutOnSaleType == "TimedAuction"&& showlist == "true" &&
             <div className="countdown_panel">
            <div className="countdown_panel_div">
              <Countdown
                date={enddate}
                autoStart={true}
                onStart={() => startdate}
                renderer={renderer}
              ></Countdown>
            </div>
          </div>}
          {showlist=='false'&&auction=="true" &&
            <div className="countdown_panel">
            <div className="countdown_panel_div">
            <span>{auctionTxt}</span>
            </div>
                </div>
                }

            </div>
           
            <h2>{item.tokenName}</h2>
            {/* <div className="creator_details">
            <img src={`${config.Back_URL}/images/noimage.png`} alt="Owner" className="img-fluid" />
                <p>PixelBuddyJam</p>
            </div> */}
            <hr className="nft_hr" />
            <div className="d-flex justify-content-between align-items-end">
                <div>
                <h3>
                  {(item.PutOnSale == false || (item.PutOnSale == true && item.PutOnSaleType=='FixedPrice' && item&&item.tokenowners_current&&item.tokenowners_current.tokenPrice == 0)) && <span>Not for sale </span>}
                  {(item.PutOnSale == true && item.PutOnSaleType=='FixedPrice' &&item&&item.tokenowners_current&&item.tokenowners_current.tokenPrice > 0) && <span>{item&&item.tokenowners_current&&item.tokenowners_current.tokenPrice} {config.tokenSymbol} </span>}
                  {(item.PutOnSale == true && item.PutOnSaleType=='TimedAuction') && <span>Minimum Bid </span>}
                  {(item.PutOnSale == true && item.PutOnSaleType=='UnLimitedAuction') && <span>Open sor Bids </span>}
                  {/* {item&&item.tokenowners_current&&item.tokenowners_current.balance} of {item&&item.tokenowners_current&&item.tokenowners_current.quantity} */}
                </h3>
                <h4>
                        {(item.PutOnSale == true && item.PutOnSaleType=='TimedAuction') && <span>{item.minimumBid} {config.tokenSymbol} </span>}
                        {(item.PutOnSaleType!='TimedAuction') && <span>Place a bid</span>}
                    </h4>
                </div>
                <div className="">
                    {/* <div className="masonry_likes">
                        <i class="bi bi-chat-dots mr-2"></i>
                        <span>50</span>
                    </div> */}
                    <div className="masonry_likes" onClick={() => hitLike(item)}>
                        {
                          (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1) ?
                            (<i className="fas fa-heart mr-2"></i>) :
                            (<i className="bi bi-heart mr-2"></i>)
                        }
                        <span class={item.tokenCounts + '-likecount'}>{item.likecount}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}