import React, {
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'

import EXCHANGE from '../../ABI/EXCHANGE.json'
import BEP721 from '../../ABI/BEP721.json'
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction,
    TokenPriceChange_update_Action
} from '../../actions/v1/token';

import {
    getCurAddr,
    halfAddrShow
} from '../../actions/v1/User';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress = config.exchangeAddress;

export const PutOnSaleRef = forwardRef((props, ref) => {

    const history = useHistory();

    const [BuyerName, Set_BuyerName] = React.useState('');
    const [blns, Set_blns] = React.useState('');
    const [dethBln, Set_dethBln] = React.useState('');
    const [bidProfile1, Set_bidProfile1] = React.useState([]);

    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');

    const [ValidateError, Set_ValidateError] = React.useState({});
    const [YouWillGet, Set_YouWillGet] = React.useState(0);

    console.log('props.item.tokenPrice',props.item);

    const [TokenPrice, Set_TokenPrice] = React.useState(0);
    const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);
    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
          var value = e.target.value;
          switch(e.target.name) {
            case 'TokenPrice':
                Set_TokenPrice(value);
                if(value != '' && isNaN(value) == false && value > 0) {
                    var weii = value * config.decimalvalues;
                    var per = (weii * config.fee) / 1e20;
                    Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
                }
                ItemValidation({TokenPrice:value});
              break;
            default:
              // code block
          }
        }
    }
    const onKeyUp = (e) => {
        var charCode = e.keyCode;
        console.log("charCode:",charCode)
        if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
          var ValidateError = {};
          Set_ValidateError(ValidateError);
        }else{
          var ValidateError = {};
          ValidateError.TokenPrice = '"Token Price" must be a number';
          Set_TokenPrice("");
          Set_ValidateError(ValidateError);
        //   return false;
        }
      }

      const ItemValidation = async (data={}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice!='undefined')?data.TokenPrice:TokenPrice;
    
        if(Chk_TokenPrice == '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if(Chk_TokenPrice == 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        } 
        else if(isNaN(Chk_TokenPrice) == true) {
          ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        else if(Math.sign( TokenPrice)<0) {
            ValidateError.TokenPrice = 'Price must be a possitive number';
          }
        else if(TokenPrice_Initial > 0 && Chk_TokenPrice >= TokenPrice_Initial) {
          ValidateError.TokenPrice = '"Token Price" must be less than '+TokenPrice_Initial;
        }else{
            delete ValidateError.TokenPrice;
        }
        // else if(Chk_TokenPrice > UserAccountBal) {
        //     ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
        // }
        // else if(Chk_TokenPrice > UserAccountBal) {
        //     ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance1';
        // }
        // else {
        //     await props.GetUserBal();
        //     if(Chk_TokenPrice > UserAccountBal) {
        //         ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance2';
        //     }
        //     else {
        //         delete ValidateError.TokenPrice;
        //     }
        // }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }
    function convert(n){
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead,decimal,pow] = n.toString()
            .replace(/^-/,"")
            .replace(/^([0-9]+)(e.*)/,"$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }

    async function FormSubmit(){
        var errors = await ItemValidation();
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }

        if(window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if(
                web3
                && web3.eth
            ) {
                var CoursetroContract = new web3.eth.Contract(
                    BEP721,
                    config.singleContract
                );
// console.log("orderplace:",(TokenPrice * config.decimalvalues).toString())    
                // var int=parseInt((TokenPrice * config.decimalvalues))
                Set_FormSubmitLoading('processing');
                var newtoken =convert(TokenPrice * config.decimalvalues)

                CoursetroContract.methods
                .orderPlace(
                    props.item.tokenCounts,
                    (newtoken).toString()
                )
                .send({ from: props.Accounts })
                .then(async (result) => {
                    Set_FormSubmitLoading('done');
                    console.log('result : ', result);
                    var postData = {
                        tokenOwner: UserAccountAddr,
                        tokenCounts: props.item.tokenCounts,
                        tokenPrice: TokenPrice,
                        blockHash: result.blockHash,
                        transactionHash: result.transactionHash
                    }
                    var Resp = await TokenPriceChange_update_Action(postData)
                    if (Resp.data && Resp.data.RetType && Resp.data.RetType=='success') {
                        toast.success("Collectible price changed successfully", toasterOption)
                        window.$('#PutOnSale_modal').modal('hide');
                        setTimeout(() => { history.push('/'); }, 2000);
                    }
                })
                .catch((error) => {
                    Set_FormSubmitLoading('error');
                    console.log('error : ', error);
                    toast.error('Order not placed', toasterOption)
                })
            }
        }
    }

    var {
        item,
        UserAccountAddr,
        UserAccountBal,
        Set_NoOfToken,
        NoOfToken
    } = props;
// console.log("click_item:",item)
    useEffect(() => {
        Set_ValidateError({});
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            async PutOnSale_Click(item,ownerdetail) {
                console.log("put_on_sale:",ownerdetail)
                var connectwallet=localStorage.getItem("yedipsbardre");
                if(!connectwallet){
                  toast.error("Please connect to a Metamask wallet", toasterOption);
                  return false;
                }
                props.Set_HitItem(item);
                // Set_TokenPrice(ownerdetail.tokenPrice);
                Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                Set_ValidateError({});
                window.$('#PutOnSale_modal').modal('show');
            }
        }),
    )
    return (
        <div class="modal fade primary_modal" id="PutOnSale_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h5 class="modal-title" id="accept_modalLabel">{TokenPrice_Initial==0 || TokenPrice_Initial==null ?'Put On Sale':'Change Price'}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={()=>window.location.reload(false)}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-0">
                        <div className="img_accept text-center">
                            {
                                item && item!=null && item!=undefined && item!="" && item.image && item.image.split('.').pop() == "mp4" ?
                                  <video src={`${config.ipfsurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                                :<div>
                                {( item && item!=null && item!=undefined && item!="" && item.image && item.image.split('.').pop() == 'mp3') ?(
                                  <img src={`${config.Back_URL}/images/music.png`}  alt="Collections" className="img-fluid img_full_w" />
                                ):(
                                  <img src={`${config.ipfsurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
                                )}
                                </div>
                             }
                        </div>
                        <p className="text-center accept_desc" >
                            <span className="buy_desc_sm" styel={{ fontSize: 12 }}>You are about to Place Order for</span>
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{item.tokenName}</span>
                            <span className="buy_desc_sm pl-2" styel={{ fontSize: 12 }} >for</span><br />
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                {
                                item.userprofile && item.userprofile.name ?
                                <span >{item.userprofile.name}</span>
                                :
                                item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner && <span >{halfAddrShow(item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner)}</span>                                }
                            </span>
                        </p>
                        <form className="bid_form" action="#">
                            <div className="bor_bot_modal mb-3 px-4 ">
                                <div className="mx-0 pb-3"></div>
                                <label for="qty">Enter Price  </label>
                                <div class="mb-3 input_grp_style_1">
                                    <input
                                        type="text"
                                        className="form-control primary_inp text-center"
                                        name="TokenPrice"
                                        id="TokenPrice"
                                        onChange={inputChange}
                                        placeholder="e.g. 10"
                                        autoComplete="off"
                                        // onKeyUp={onKeyUp}
                                        // value={NoOfToken}
                                    />
                                    {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                </div>
                            </div>
                        </form>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Service fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{config.fee/config.decimalvalues}% <span>{config.tokenSymbol}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">You will get</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{YouWillGet} <span>{config.tokenSymbol}</span></p>
                            </div>
                        </div>
                        <form className="px-4">
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="create_btn btn-block"
                                    onClick={() => FormSubmit()}
                                    disabled={(FormSubmitLoading=='processing')}
                                >
                                    {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    Sign sell order
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
})

