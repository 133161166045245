import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import './index.css';
import './nft.css';

import NFT from "views/nft";
import Create from "views/create";
import CreateSingle from "views/create-single";
import createMultiple from "views/create-multiple";
import Info from "views/info";
import createSingleMultiple from "views/create-single-multiple";
import Lottery from "views/lottery";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <BrowserRouter basename="/">
    <ToastContainer />
    <Switch>
      <Route path="/lottery" component={Lottery} />
      <Route path="/info/:tokenidval" component={Info} />
      <Route path="/create-multiple" component={createSingleMultiple} />
      <Route path="/create-single" component={createSingleMultiple} />
      <Route path="/create" component={Create} />
      <Route path="/nft" component={NFT} /> 
      <Route exact path="/*" component={NFT}>
        <Redirect to="/nft" />
      </Route>     
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
