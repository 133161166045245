import React, {
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'

import EXCHANGE from '../../ABI/EXCHANGE.json'
import BEP721 from '../../ABI/BEP721.json';
import BEP1155 from '../../ABI/BEP1155.json';
import SpideybarderABI from '../../ABI/DETH.json';
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction,
    TokenPriceChange_update_Action,
    PurchaseNow_Complete_Action
} from '../../actions/v1/token';

import {
    getCurAddr,
    halfAddrShow
} from '../../actions/v1/User';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress = config.exchangeAddress;
const contractAddr    = config.smartContract;

export const PurchaseNowRef = forwardRef((props, ref) => {

    const history = useHistory();

    var {
        UserAccountAddr,
        UserAccountBal,
        TokenBalance,
        MyItemAccountAddr,
    } = props;

    const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
    const [PurchaseCallStatus, setPurchaseCallStatus] = React.useState('init');
    const [price, Set_Price] = React.useState(0);

    var PurchaseBalance = 0;
    var PurchaseCurrency = '';

    if(config.PurchaseTransferType == 'token') {
        PurchaseBalance = TokenBalance;
        PurchaseCurrency = config.tokenSymbol;
    }
    else {
        PurchaseBalance = UserAccountBal;
        PurchaseCurrency = config.currencySymbol;
    }

    const [BuyerName, Set_BuyerName] = React.useState('');
    const [blns, Set_blns] = React.useState('');
    const [dethBln, Set_dethBln] = React.useState('');
    const [bidProfile1, Set_bidProfile1] = React.useState([]);

    const [MultipleWei, Set_MultipleWei] = React.useState(0);
    const [NoOfToken, Set_NoOfToken] = React.useState(1);
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
    const [ValidateError, Set_ValidateError] = React.useState({});
    const [YouWillPay, Set_YouWillPay] = React.useState(0);
    const [TokenPrice, Set_TokenPrice] = React.useState(0);
    const [MetaMaskAmt, setMetaMaskAmt] = React.useState(0);

    async function buymodal(){
        setTimeout(() => window.$('#PurchaseNow_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(false), 900);
    }
    // const PriceCalculate = async (data={}) => {
    //     var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
    //     var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
    // var newPrice = item.type == 721 ? price*config.decimalvalues : quantity *(price*config.decimalvalues);
    //     var per = (newPrice * config.fee) / 1e20;
    //     var mulWei = newPrice + per;
    //     Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
    //     Set_MultipleWei(mulWei);
    //     Set_Price(newPrice)
    //     // //("PriceCalculate : ",data, price,quantity,newPrice,weii,per,mulWei,(mulWei / config.decimalvalues).toFixed(config.toFixed))   
    // }
    const PriceCalculate = async (data={}) => {
        var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
    var newPrice = item.type == 721 ? price*config.decimalvalues : quantity *(price*config.decimalvalues);
        var per = (newPrice * config.fee) / 1e20;
        var mulWei = newPrice + per;
        Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
        setMetaMaskAmt(mulWei);
       // Set_Price(newPrice)
        // //("PriceCalculate : ",data, price,quantity,newPrice,weii,per,mulWei,(mulWei / config.decimalvalues).toFixed(config.toFixed))   
    }
    const onKeyUp = (e) => {
        var charCode = e.keyCode;
        if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
          var ValidateError = {};
          Set_ValidateError(ValidateError);
        }else{
          var ValidateError = {};
          ValidateError.NoOfToken = '"Quantity" must be a number';
          Set_NoOfToken("");
          Set_ValidateError(ValidateError);
        }
      }
      
    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
            var value = e.target.value;
            switch(e.target.name) {
                case 'NoOfToken':
                    Set_NoOfToken(value);
                    PriceCalculate({quantity:value});
                    break;
                case 'TokenPrice':
                    Set_TokenPrice(value);
                    if(value != '' && isNaN(value) == false && value > 0) {
                        PriceCalculate({price:value});
                    }
                    break;
                default:
                // code block
            }
            // ItemValidation({TokenPrice:value});
        }
    }
    const ItemValidation = async (data={}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice!='undefined')?data.TokenPrice:TokenPrice;
        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;

        var Collectible_balance = 0;
        if(item && item.tokenowners_current && item.tokenowners_current.balance) {
            Collectible_balance = item.tokenowners_current.balance;
        }

        if(quantity == '') {
            ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
        }
        else if(quantity == 0) {
            ValidateError.NoOfToken = '"Quantity" must be greater than 0';;
        } 
        else if(isNaN(quantity) == true) {
          ValidateError.NoOfToken = '"Quantity" must be a number';
        }
        if(quantity > Collectible_balance) {
            ValidateError.NoOfToken = '"Quantity" must be below on '+Collectible_balance;
        }

        if(Chk_TokenPrice == '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if(Chk_TokenPrice == 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        } 
        else if(isNaN(Chk_TokenPrice) == true) {
          ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        else if(YouWillPay > dethBln) {
            ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
        }
        else {
            await props.GetUserBal();
            if(YouWillPay > dethBln) {
                ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
            }
            else {
                delete ValidateError.TokenPrice;
            }
        }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }

    async function FormSubmit(){
        console.log('purchasebal : ', TokenPrice,UserAccountBal);
        var errors = await ItemValidation();
console.log("purchase_err",errors)
        if (dethBln==0) {
            toast.error("SpideyBarter Token Balance Insufficient", toasterOption);
            return false;
        }

        if (YouWillPay>dethBln) {
            toast.error("SpideyBarter Token Balance Insufficient", toasterOption);
            return false;
        }
        
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }

        if(window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if(
                web3
                && web3.eth
            ) {

                if(config.tokenSymbol == 'SBNFT') {
                    
                    var SpideybarContract    = new web3.eth.Contract(SpideybarderABI, config.Spideybarder);
                    var currAddr             = window.web3.eth.defaultAccount;
                    var tokenBal             = await SpideybarContract.methods.balanceOf(currAddr).call();
                    var tokenBalance         = tokenBal / config.decimalvalues;
                    if(tokenBalance==0){
                        toast.error("SpideyBarter Token Balance Insufficient", toasterOption)
                        return false;
                    }
                    if(YouWillPay>tokenBalance){
                        toast.error("SpideyBarter Token Balance Insufficient", toasterOption)
                        return false;
                    }
                    window.$('.modal').modal('hide');
                    window.$('#PurchaseStep_modal').modal('show');
                }
                else {
                    var tokenContractAddress = item.contractAddress.toString();
                    var tokenType = item.type.toString();
                    var bal = parseInt(item.balance);

                    var web3   = new Web3(window.ethereum);
                    var CoursetroContract = new web3.eth.Contract(
                        BEP721,
                        config.singleContract
                    );
                    // var sendAmount = (item.tokenPrice * config.decimalvalues).toString();
                    var sendAmount = (item.tokenowners_current.tokenPrice * config.decimalvalues).toString();
                    Set_FormSubmitLoading('processing');
                    CoursetroContract.methods
                    .saleToken(
                        item.tokenowners_current.tokenOwner,
                        item.tokenCounts,
                        sendAmount
                    )
                    .send({
                        from: props.Accounts,
                        value:MultipleWei
                    })
                    .then(async (result) => {
                        Set_FormSubmitLoading('done');
                        console.log('result : ', result);
                        var postData = {
                            tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                            UserAccountAddr: UserAccountAddr, // new owner
                            tokenCounts: item.tokenCounts,
                            tokenType: item.type,
                            NoOfToken: item.type == 721 ? 1 : NoOfToken,
                            transactionHash: result.transactionHash
                        }
                        var Resp = await PurchaseNow_Complete_Action(postData);
                        console.log("purchase_completed:",Resp)
                        if (Resp.data && Resp.data.toast && Resp.data.toast.type=='success') {
                            toast.success("Collectible purchase successfully", toasterOption)
                            window.$('.PurchaseNow_modal').modal('hide');
                            setTimeout(() => { window.location.reload(false); }, 2000);
                        }
                    })
                    .catch((error) => {
                        Set_FormSubmitLoading('error');
                        console.log('error : ', error);
                        toast.error('Order not placed', toasterOption);
                    })
                }
            }
        }
    }

    async function FormSubmit_StepOne(){
        if(window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if(
                web3
                && web3.eth
            ) {
                var tokenContractAddress = item.contractAddress.toString();
                var tokenType            = item.type.toString();
                var bal                  = parseInt(item.balance);
                var web3                 = new Web3(window.ethereum);
                var CoursetroContract    = new web3.eth.Contract(SpideybarderABI,config.Spideybarder)
                var sendAmount           = (YouWillPay * config.decimalvalues).toString();
                setApproveCallStatus('processing');
                var SpideybarContract    = new web3.eth.Contract(SpideybarderABI, config.Spideybarder);
                var currAddr             = window.web3.eth.defaultAccount;
                var tokenBal             = await SpideybarContract.methods.balanceOf(currAddr).call();
                var tokenBalance         = tokenBal / config.decimalvalues;
                var getAllowance         = await SpideybarContract.methods.allowance(currAddr,config.singleContract).call();
                var sendVal              = parseInt(MetaMaskAmt)+parseInt(getAllowance);
                if(tokenBalance==0){
                    toast.error("SpideyBarter Token Balance Insufficient", toasterOption)
                    return false;
                }
                if(YouWillPay>tokenBalance){
                    toast.error("SpideyBarter Token Balance Insufficient", toasterOption)
                    return false;
                }

               await CoursetroContract.methods
                .approve(
                    config.singleContract,
                    sendVal.toString()
                )
                .send({
                    from: props.Accounts,
                })
                .then(async (result) => {
                    setApproveCallStatus('done');
                })
                .catch((error) => {
                    setApproveCallStatus('tryagain');
                    console.log('error : ', error);
                    toast.error('Order not approved', toasterOption);
                })
            }
        }
    }

    async function FormSubmit_StepTwo(){
        if(window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if(
                web3
                && web3.eth
            ) {
         console.log("list_item:",item)
                var tokenContractAddress = item.contractAddress.toString();
                var tokenType            = item.type.toString();
                var bal                  = parseInt(item.balance);
                var web3                 = new Web3(window.ethereum);
                var CoursetroContract    = new web3.eth.Contract(BEP721,config.singleContract);
                 var sendAmount           = (item&&item.tokenowners_current&&
                    item.tokenowners_current.tokenPrice * config.decimalvalues);
                // var sendAmount           = (item.tokenPrice * config.decimalvalues).toString();
                var SpideybarContract    = new web3.eth.Contract(SpideybarderABI, config.Spideybarder);
                var currAddr             = window.web3.eth.defaultAccount;
                var tokenBal             = await SpideybarContract.methods.balanceOf(currAddr).call();
                var tokenBalance         = tokenBal / config.decimalvalues;
                var Checkbal             = sendAmount/config.decimalvalues;
          
                if(tokenBalance==0){
                    toast.error("SpideyBarter Token Balance Insufficient", toasterOption)
                    return false;
                }
                if(YouWillPay>tokenBalance){
                    toast.error("SpideyBarter Token Balance Insufficient", toasterOption)
                    return false;
                }

                setPurchaseCallStatus('processing');
                CoursetroContract.methods
                .salewithToken(
                    config.tokenSymbol,
                    item.tokenowners_current.tokenOwner,
                    item.tokenCounts,
                    (sendAmount*NoOfToken).toString(),
                    // tokenContractAddress,
                    // tokenType,
                    // item.type == 721 ? 1 : NoOfToken,
                    // config.tokenSymbol
                )
                .send({
                    from: props.Accounts
                })
                .then(async (result) => {
                    setPurchaseCallStatus('done');
                    var postData = {
                        tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                        UserAccountAddr: UserAccountAddr, // new owner
                        tokenCounts: item.tokenCounts,
                        tokenType: item.type,
                        NoOfToken: item.type == 721 ? 1 : NoOfToken,
                        transactionHash: result.transactionHash
                    }
                    var Resp = await PurchaseNow_Complete_Action(postData);
                    console.log("completed_process:",Resp)
                    if (Resp.data && Resp.data.toast && Resp.data.toast.type=='success') {
                        toast.success("Collectible purchase successfully", toasterOption)
                        window.$('.PurchaseNow_modal').modal('hide');
                        setTimeout(() => { window.location.reload(false); }, 2000);
                    }
                })
                .catch((error) => {
                    console.log('error : ', error);
                    setPurchaseCallStatus('tryagain');
                    toast.error('Order not placed', toasterOption);
                })
            }
        }
    }

    const [item, Set_item] = React.useState(props.item);

    async function balanceCheck(){
        try{
            var web3                 = new Web3(window.ethereum);
            var SpideybarContract    = new web3.eth.Contract(SpideybarderABI, config.Spideybarder);
            var currAddr             = window.web3.eth.defaultAccount;
            var tokenBal             = await SpideybarContract.methods.balanceOf(currAddr).call();
            var tokenBalance         = tokenBal / config.decimalvalues;
            Set_dethBln(tokenBalance)
        }catch(err){
        }
    }
    useEffect(() => {
        Set_ValidateError({});
        balanceCheck();
        ItemValidation({TokenPrice:TokenPrice});
    }, [
        TokenPrice
    ]);

    useImperativeHandle(
        ref,
        () => ({
            async PurchaseNow_Click(item, BuyOwnerDetail={}) {
                var connectwallet=localStorage.getItem("yedipsbardre");
                if(!connectwallet){
                  toast.error("Please connect to a Metamask wallet", toasterOption);
                  return false;
                }
                if(BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner != 'undefined') {
                    item.tokenowners_current = {};
                    item.tokenowners_current = BuyOwnerDetail;
                }

                if(item && item.tokenowners_current && item.tokenowners_current.tokenPrice) {
                    Set_item(item);
                    Set_TokenPrice(item.tokenowners_current.tokenPrice);
                    Set_NoOfToken(1);
                    PriceCalculate({quantity:1,price:item.tokenowners_current.tokenPrice});
                    window.$('#PurchaseNow_modal').modal('show');
                }
            }
        }),
    )

    return (
        <div>
            <div class="modal fade primary_modal PurchaseNow_modal" id="PurchaseNow_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                    <h5 class="modal-title" id="buy_modalLabel">Checkout</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={(buymodal)}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-0">
                        <div className="row mx-0">
                            <div className="col-12 col-sm-3 px-4">
                                <p className="buy_title_sm">Owner</p>
                            </div>
                            <div className="col-12 col-md-6 px-4">
                                {item.userprofile && item.userprofile.name? item.userprofile && item.userprofile.name : halfAddrShow(item&&item.tokenowners_current&&item.tokenowners_current. tokenOwner)}
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-md-3 px-4">
                                <p className="buy_title_sm">Buyer</p>
                            </div>
                            <div className="col-12 col-md-6 px-4">
                                {MyItemAccountAddr ?  halfAddrShow(MyItemAccountAddr) : halfAddrShow(UserAccountAddr)}
                            </div>
                        </div>
                        <div className="bor_bot_modal mb-3 px-4 "></div>
                        <form className="bid_form" action="#">
                            {item.type == 721 ?(''):
                            <div className="bor_bot_modal mb-3 px-4 ">
                                <label for="qty">Quantity</label>
                                <div class="mb-3 input_grp_style_1">
                                    <input
                                        type="text"
                                        className="form-control primary_inp text-center"
                                        name="NoOfToken"
                                        id="NoOfToken"
                                        onChange={inputChange}
                                        placeholder="e.g. 2"
                                        autoComplete="off"
                                        value={NoOfToken}
                                        onKeyUp={onKeyUp}

                                    />
                                    {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                                    {!ValidateError.NoOfToken && ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                </div>
                            </div>}
                        </form>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Your balance</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{PurchaseBalance} {PurchaseCurrency}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Your token balance</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{dethBln} {config.tokenSymbol}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Price</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{TokenPrice} {config.tokenSymbol}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Service fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{config.fee/config.decimalvalues}% <span>{config.tokenSymbol}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">You will pay</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{YouWillPay} <span>{config.tokenSymbol}</span></p>
                            </div>
                        </div>
                        <form className="px-4">
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="primary_btn btn-block"
                                    onClick={() => FormSubmit()}
                                    disabled={(FormSubmitLoading=='processing')}
                                >
                                    {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    Proceed to payment
                                </Button>
                                <Button className="cancel_btn  btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
            <div class="modal fade primary_modal PurchaseStep_modal" id="PurchaseStep_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                        <h5 class="modal-title" id="PurchaseStepLabel">Follow Steps</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="text-center">
                                    <p className="mt-3 purchase_desc text-center">Approve the transaction</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepOne()}
                                        className={"create_btn " + ( (ApproveCallStatus=='processing' || ApproveCallStatus=='done') ? 'create_btn' : 'btn-block')}
                                        disabled={(ApproveCallStatus=='processing' || ApproveCallStatus=='done')}
                                    >
                                        {ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {ApproveCallStatus == 'init' && 'Approve'}
                                        {ApproveCallStatus == 'processing' && 'In-progress...'}
                                        {ApproveCallStatus == 'done' && 'Done'}
                                        {ApproveCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                                <div className="text-center my-3">
                                <p className="mt-3 purchase_desc text-center">Send transaction with your wallet</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepTwo()}
                                        className={"create_btn " + ( (ApproveCallStatus!='done' || PurchaseCallStatus=='processing' || PurchaseCallStatus=='done') ? 'create_btn' : 'btn-block')}
                                        disabled={(ApproveCallStatus!='done' || PurchaseCallStatus=='processing' || PurchaseCallStatus=='done')}
                                    >
                                        {PurchaseCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {PurchaseCallStatus == 'init' && 'Purchase'}
                                        {PurchaseCallStatus == 'processing' && 'In-progress...'}
                                        {PurchaseCallStatus == 'done' && 'Done'}
                                        {PurchaseCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

