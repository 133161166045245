import React,{useEffect} from 'react'
import classNames from "classnames";
import { Link } from "react-router-dom";
// material ui
import { AppBar, Toolbar, IconButton, Button, Menu, MenuItem, Typography  } from "@material-ui/core";
import { MenuOpen, Notifications, AccountBalanceWallet, MeetingRoom } from '@material-ui/icons';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import WalletConnectProvider from "@walletconnect/web3-provider"
import config from '../../lib/config';
import { getprovider } from 'views/separate/walletconnect';

const HeaderDashboard =(props)=> {
    const { classes, handleDrawerOpen, open, handleMenu, handleClose, anchorEl } = props;
    const [currAddr, setcurrAddr] = React.useState("");
    const [blns, setBln]          = React.useState(0);


  useEffect(() => {
    getconnect();
  }, []);
    async function getconnect() {
      if (window.ethereum) {
        try {
            let {provider, address} = await getprovider();
            setcurrAddr(address);
            var web3 = new Web3(provider);
            let balance = await web3.eth.getBalance(address);
            balance = balance /1000000000000000000;;
            setBln(balance);
            
            // window.ethereum.enable().then(async function () {
            //   const web3 = new Web3(window.web3.currentProvider);
            //   if (window.web3.currentProvider.isMetaMask === true) {
            //     await web3.eth.getAccounts(async function (error, result) {
            //       var currAddr = window.web3.eth.defaultAccount;
            //       setcurrAddr(currAddr);
            //       var web3 = new Web3(window.ethereum);
            //       window.web3.eth.getBalance(result[0],async (error, balance) => {
            //           var currbal = balance / 1000000000000000000;
            //           setBln(currbal.toFixed(5));
            //         }
            //       );
             
            //     });
            //   }
            // });
        } catch (err) {
          console.log(err,"error")
        }
      } else {
       // toast.success("Please Add Metamask External", toasterOption);
      }
    }
    async function disconnectMetamask() {
      if (localStorage.getItem("yedipsbardre")) {
        localStorage.removeItem("yedipsbardre");
        localStorage.removeItem('account');
        if (
          localStorage.getItem("walltype") &&
          localStorage.getItem("walltype") != null &&
          localStorage.getItem("walltype") != undefined &&
          localStorage.getItem("walltype") != "" &&
          localStorage.getItem("walltype") == "trust"
        ) {
        const provider = new WalletConnectProvider({ rpc:{
          [56]:"https://bsc-dataseed1.binance.org/"},
          chainId:56
            });
          await provider.disconnect();
          console.log("logged out");
          
        localStorage.removeItem('walltype');
        }
      } 
      
      setTimeout(() =>window.location.reload(false),600)
    }

  return (
    <div>
      <AppBar
          position="fixed"
          className={classes.appBar + " dash_header"}
          fooJon={classNames(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar disableGutters={true}>
          <div className="topbar_left">          
            <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} className={classes.menuButton}>
              <MenuOpen
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed
                }}
              />
            </IconButton>
            <Link to="/"><img src={require("../../assets/images/logo.png")} alt="Logo" className="logo_icon"/></Link>
          </div>
          <div className="topBarRight">
            {(!localStorage.getItem("yedipsbardre"))?(
              <Button className="primary_btn" data-toggle="modal" data-target="#wallet_modal">Connect</Button>
              ):(
                <div>
                <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className="profileButton"
            >
              <AccountBalanceWallet />
              <Typography className="wallet_address">{currAddr}</Typography>
            </IconButton>
            <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
          <MenuItem>
            <div className="dropProfilePic">
              <Typography className="dropProfileName">{blns} BNB</Typography>
            </div>
          </MenuItem>
            {/* <MenuItem onClick={handleClose}>My Account</MenuItem> */}
            <MenuItem onClick={()=>disconnectMetamask()}><MeetingRoom /> Logout</MenuItem>
          </Menu>
            </div>
            )
          }
            {/* <a href="#"><Notifications /></a> */}
           {/*  <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className="profileButton"
            >
              <AccountBalanceWallet />
              <Typography className="wallet_address">0x75Cf28f29266A413eC878CE5C4a1c9682C819586</Typography>
            </IconButton>*/}

            
          </div>
        </Toolbar>
        
      </AppBar>

      

    </div>
)
}

export default HeaderDashboard;